<script type='ts' setup>
import { useAclStore } from '../store/acl'
import Footer from '~/components/layouts/footer.vue'
import Nav from '~/components/layouts/nav.vue'
import { useAlertStore } from '~/store/alert'

const $acl = useAclStore()
const $alert = useAlertStore()

onMounted(() => {
  $acl.checkSession()
})

const $nav = useNavStore()
useHead({
  title: computed(() => $nav.title ? `${$nav.title} - Cel-Elements` : 'Cel-Elements'),
})
</script>

<template>
  <a-layout theme="light">
    <div class="bg-[#8DB600] w-full h-[186px] absolute top-0 left-0"> </div>
    <a-alert v-if="$alert?.error" :message="$alert.error" type="error" />
    <Nav />
    <slot />

    <Footer />
  </a-layout>
</template>

<style scoped>

</style>
